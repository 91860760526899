exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-of-practice-js": () => import("./../../../src/pages/code-of-practice.js" /* webpackChunkName: "component---src-pages-code-of-practice-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-agreement-js": () => import("./../../../src/pages/our-agreement.js" /* webpackChunkName: "component---src-pages-our-agreement-js" */),
  "component---src-pages-our-partnership-js": () => import("./../../../src/pages/our-partnership.js" /* webpackChunkName: "component---src-pages-our-partnership-js" */),
  "component---src-templates-pub-list-js": () => import("./../../../src/templates/pubList.js" /* webpackChunkName: "component---src-templates-pub-list-js" */)
}

